<template>
  <div class="page">
    <el-collapse accordion style="width: 1200px">
      <el-collapse-item v-for="(payWay, index) in payWayList">
        <template slot="title">
          <span>{{ payWay.name_title }} - {{ payWay.name }}</span>
          <span v-show="payWay.value == '1'" style="color: #13ce66;margin-left: 20px">开启</span>
          <span v-show="payWay.value == '0'" style="color: #ff4949;margin-left: 20px">关闭</span>
        </template>
        <el-form label-position="top">
          <el-form-item label="是否启用">
            <el-switch
              v-model="payWay.value"
              active-value="1"
              inactive-value="0"
              @change="changePayWayState($event, payWay.id)"
            />
          </el-form-item>
          <el-form-item
            v-for="(item, iindex) in payWay.sub_items"
            :label="item.name_title + ' (' + item.name + ')'"
          >
            <el-input
              v-if="!item.widget"
              v-model="item.value"
              clearable
              :disabled="payWay.value == '0'"
            />
            <tinymce
              v-if="item.widget == 'text'"
              v-model="item.value"
              placeholder="请输入内容"
              :height="200"
              :width="1100"
            />
            <div
              v-if="item.widget == 'fileupload'"
              style="display: flex; align-items: center"
            >
              <el-input v-model="item.value" clearable readonly />
              <el-button
                type="primary"
                style="margin-left: 20px"
                :disabled="payWay.value == '0'"
                @click="showSelecterHandle([index, iindex], 'file')"
                >选择</el-button
              >
            </div>
            <div
              v-if="item.widget == 'imageupload'"
              style="display: flex; align-items: center"
            >
              <el-input v-model="item.value" clearable readonly />
              <el-button
                type="primary"
                style="margin-left: 20px"
                :disabled="payWay.value == '0'"
                @click="showSelecterHandle([index, iindex], 'image')"
                >选择</el-button
              >
            </div>
          </el-form-item>
          <el-button
            type="primary"
            :disabled="payWay.value == '0'"
            @click="savePayWay(index)"
            >save</el-button
          >
        </el-form>
      </el-collapse-item>
    </el-collapse>
    <fileSelect
      :visible.sync="showSelecter"
      :state="selectState"
      @confirm="showSelecterSubmit"
    ></fileSelect>
  </div>
</template>

<script>
import {
  apiGetPaywayList,
  apiGetPaywayDetail,
  apiSetPayway,
} from "@/request/api";
import fileSelect from "@/views/components/fileSelect";
export default {
  name: "index",
  components: { fileSelect },
  data() {
    return {
      showSelecter: false,
      selectState: "",
      setIndex: [],
      payWayList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      apiGetPaywayList().then((res) => {
        if (res.code == 200) {
          this.payWayList = res.data;
        }
      });
    },
    showSelecterSubmit(data) {
      console.log(data);
      if (this.selectState == "file") {
        this.payWayList[this.setIndex[0]].sub_items[this.setIndex[1]].value =
          data[0].url;
      }
      if (this.selectState == "image") {
        this.payWayList[this.setIndex[0]].sub_items[this.setIndex[1]].value =
          data[0].origin;
      }
    },
    showSelecterHandle(index, state) {
      this.setIndex = index;
      this.showSelecter = true;
      this.selectState = state;
    },
    changePayWayState(event, id) {
      console.log(event, id);
      var data = [{ id: id, value: event }];
      apiSetPayway(data).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: res.message,
            type: "success",
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    savePayWay(index) {
      var list = [];
      this.payWayList[index].sub_items.forEach((item) => {
        var obj = { id: item.id, value: item.value };
        list.push(obj);
      });
      apiSetPayway(list).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: res.message,
            type: "success",
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style scoped>
.page {
  background-color: #fff;
  padding: 20px;
}
</style>
